import { useState, useEffect, useMemo, useRef} from "react";
import { Stack, Form, Container, OverlayTrigger, Popover } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import "../lib/scss/op-deal.scss";
import axios from "../api/axios";
import {FormSelect} from "../includes/FormCustom";

import "../lib/scss/op-report-style.scss";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2";
import { useAuth } from "../auth/AuthContext";
import Loader from "../includes/Loader";
import { useTranslation } from "react-i18next";

const ActivityReport = () =>{

    const {session} = useAuth();
    const init = useRef(false);
    const gridRef = useRef();

    const [isLoading, setIsLoading] = useState(true);
    const [trigger, setTrigger] = useState("");
    const [isReport, setIsReport] = useState(false);

    const [startDate, setStartDate] = useState(moment(new Date(`${new Date().getFullYear() - 1}-${new Date().getMonth() + 1}-${new Date().getDate()}`)).format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [userID, setUserID] = useState("");
    const [datID, setDatID] = useState("");
    const [madVal, setMadVal] = useState("");
    const [pipelineID, setPipelineID] = useState("");

    const [userList, setUserList] = useState([]);
    const [activityList, setActivityList] = useState([]);
    const [pipeList, setPipeList] = useState([]);
    const [recordFound, setRecordFound] = useState("");
    const { t } = useTranslation();

    const containerStyle = useMemo(() => ({ width: "100%", height: isReport ? "80vh" : "0vh", paddingTop: 20 }), [isReport]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const getAllUser = () =>{
        
        axios.get("ws/ws_user.php",{
            params:{
                task: "4a",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res =>{
            let data = res.data;

            if (Number(data.status) === 0) {
                var userData = data.record.map((data)=>{
                    return {label: data.user_name, value: data.user_id};
                });
                setUserList(userData);
            }else{
                setUserList([]);
            }
        })
    }

    const getAllActivity = () =>{

        axios.get("ws/ws_setting.php",{
            params:{
                task: "getActivityType",
                company: session.company_id
            }
        })
        .then(res =>{

            let data = res.data;

            if (Number(data.status) === 0) {
                var activityData = data.record.map((data)=>{
                    return {label: data.dat_title, value: data.dat_id};
                });

                setActivityList(activityData);
            }else{
                setActivityList([]);
            }
        });
    }

    const getAllPipeline = () =>{
        
        axios.get("ws/ws_setting.php",{
            params:{
                task: "getAllPipeline",
                company: session.company_id
            }
        })
        .then(res =>{
            let data = res.data;

            if (Number(data.status) === 0) {
                var pipeData = data.record.map((data)=>{
                    return {label: data.pipeline_title, value: data.pipeline_id}
                });

                setPipeList(pipeData);
            }else{
                setPipeList([]);
            }
        });
    }

    const completionStatusList = [{label:"Yes", value: "yes"},{label: "No", value: "no"}];

    useEffect(()=>{
        if (!init.current) {
            init.current = false;
            setIsLoading(false);
            getAllUser();
            getAllActivity();
            getAllPipeline();
        }
    },[]); // eslint-disable-line react-hooks/exhaustive-deps

    const CustomOverlay = (value) => {
        var string = value;
        var isCustomHeight = false;
        if (string.constructor === Array) {
            isCustomHeight = true;
        }
        
        return(
            <OverlayTrigger
                trigger={['click', 'focus']}
                placement="left"
                rootClose
                overlay={
                    <Popover>
                        <Popover.Header as={"h6"}>
                            Description
                        </Popover.Header>
                        <Popover.Body className="p-0">
                            <div style={{overflow: "auto", height: isCustomHeight ? "250px":"auto"}} className="py-2 px-4">
                                {value}
                            </div>
                        </Popover.Body>
                    </Popover>
                }
            >
                <span style={{cursor: "pointer"}}>{value}</span>
            </OverlayTrigger>
        );
    }

    const reportColumn = [
        {
            headerName: t('Report_Acitivity_HeaderName1'),
            field: "record_date_time_create",
            resizable: true,
            width: 200,
            cellRenderer: (params) => {
                if (params.data) {
                    return params.data.record_date_time_create;
                }else{
                    return <img src="https://www.ag-grid.com/example-assets/loading.gif" alt="loader" />;
                }
            }
        },
        {
            headerName: t('Report_Acitivity_HeaderName2'),
            field: "record_date_time",
            resizable: true,
            width: 200
        },
        {
            headerName: t('Report_Acitivity_HeaderName3'),
            field: "deal_title",
            resizable: true,
            width: 200,
            onCellClicked: function (params) {
                if (params.data) {
                    window.open(`${session.origin}/deal/${params.data.deal_id}`,"_blank");
                }
            },
        },
        {
            headerName: t('Report_Acitivity_HeaderName4'),
            field: "record_title",
            resizable: true,
            width: "300"
        },
        {
            headerName: t('Report_Acitivity_HeaderName5'),
            field: "record_activity_type",
            resizable: true,
            width: 200
        },
        {
            headerName: t('Report_Acitivity_HeaderName6'),
            field: "record_description",
            resizable: true,
            width: 200,
            cellRenderer: (params) => {
                if (params.data) {
                    var res = params.data.record_description;
                    if(res !== undefined && res !== null && res !== ''){
                        res = res.replace(/<br>/g, "\r\n");
                        res = res.replace(/<li>/g, "\r\n");
                        res = res.replace(/<[^>]+>/g, '');
                        res = res.replace(/&nbsp;/g, '');
                    }
                    
                    return CustomOverlay(res);
                }
            }
        },
        {
            headerName: t('Report_Acitivity_HeaderName7'),
            field: "ASSIGN_USER",
            resizable: true,
            width: 200
        },
        {
            headerName: t('Report_Acitivity_HeaderName8'),
            field: "record_activity_mad",
            resizable: true,
            width: 200
        },
        {
            headerName: t('Report_Acitivity_HeaderName9'),
            field: "activity_latitude",
            resizable: true,
            width: 200
        },
        {
            headerName: t('Report_Acitivity_HeaderName10'),
            field: "activity_longitude",
            resizable: true,
            width: 200
        },
        {
            headerName: t('Report_Acitivity_HeaderName11'),
            field: "",
            resizable: true,
            width: 200,
            cellRenderer: (params) => {
                if (params.data) {
                    if (params.data.prospect_name) {
                        return params.data.prospect_name;
                    }else if (params.data.prospect_name_alt) {
                        return params.data.prospect_name_alt;
                    }else {
                        return "";
                    }
                }
            },
            onCellClicked: function (params) {
                if (params.data) {
                    if (params.data.prospect_id && Number(params.data.prospect_id) !== 0) {
                        window.open(`${session.origin}/prospect/${params.data.prospect_id}`,"_blank");
                    }else if (params.data.prospect_id_alt && Number(params.data.prospect_id_alt) !== 0) {
                        window.open(session.origin+"/prospect/"+params.data.prospect_id_alt,"_blank");
                    }else{
                        return "";
                    }
                }
            }
        },
        {
            headerName: t('Report_Acitivity_HeaderName12'),
            field: "record_org",
            resizable: true,
            width: 200
        }
    ];


    const reportDataSource = useMemo(()=>{
        return {
            rowCount: undefined,
            getRows: (params) =>{

                axios.get("ws/ws_activityreport.php",{
                    params:{
                        task: "getActivitiesDataV2",
                        ds: startDate,
                        de: endDate,
                        user: userID,
                        dat: datID,
                        mad: madVal,
                        pipeid: pipelineID,
                        startRow: params.startRow,
                        utoken: session.user_ac_token,
                        ctoken: session.company_token
                    }
                })
                .then(res =>{
                    let data = res.data;

                    if (Number(data.status) === 0) {
                        if (Number(data.totalRecord) > 0) {
                            setIsReport(true);
                        }else{
                            setIsReport(false);
                        }
                    }else{
                        setIsReport(false);

                        Swal.fire({
                            icon: 'error',
                            title: t('Report_alert_Title'),
                            text: t('Report_alert_Text')
                        });
                    }

                    setTimeout(() => {
                        var dealInfo = [];
                        var totalRecord = 0;
                        if (Number(data.status) === 0) {
                            dealInfo = data.record;
                            totalRecord = Number(data.totalRecord);
                            setRecordFound(totalRecord);
                        } else {
                            dealInfo = [];
                            totalRecord = 0;
                            setRecordFound(totalRecord);
                        }

                        var rowsThisPage = dealInfo;
                        var lastRow = -1;

                        if (Number(totalRecord) <= params.endRow) {
                            lastRow = totalRecord;
                        }

                        params.successCallback(rowsThisPage, lastRow);
                    }, 500);

                });
            }
        }
    },[trigger, session]); // eslint-disable-line react-hooks/exhaustive-deps

    const reportColDef = useMemo(() => {
        return {
          sortable: false,
          filter: false,
        };
    }, []);

    const handleDownload = () =>{

        var params = {
            fileName : 'activity-report.csv',
            processCellCallback : function(params) {

                if(params.column.colId === 'auto_increment_num'){
                    return params.node.data.temprecord_id;
                }else if(params.column.colId === 'deal_title'){
                    return params.node.data.deal_title;
                }else{
                    var res = params.value;
                    if(res !== undefined && res !== null && res !== ''){
                        res = res.replace(/<br>/g, "\r\n");
                        res = res.replace(/<li>/g, "\r\n");
                        res = res.replace(/<[^>]+>/g, '');
                        res = res.replace(/&nbsp;/g, '');
                    }
                    
                    return res;
                }

            }
        };

        gridRef.current.api.exportDataAsCsv(params);
    }

    return(
        <Container fluid style={{ padding: 0 }}>
            {isLoading ? (
               <Loader /> 
            ):(
                <div className="report-main">
                    <div className="report-filter">
                        <section className="header shadow-sm">
                            <div className="op-text-bigger fw-semibold text-uppercase p-0 m-0">{t('Report_FilterCriteria')}</div>
                        </section>

                        <section className="content">
                            <Form.Group className="mb-3">
                                <Form.Control type="date" value={startDate} onChange={(e)=>{setStartDate(moment(e.target.value).format("YYYY-MM-DD"))}}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control type="date" value={endDate} onChange={(e)=>{setEndDate(moment(e.target.value).format("YYYY-MM-DD"))}}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <FormSelect 
                                    placeholder={t('Report_AllUser')}
                                    options={userList}
                                    isClearable={true} 
                                    isSearchable={true}
                                    onChange={(e)=>{e !== null ? setUserID(e.value):setUserID("")}}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <FormSelect 
                                    placeholder={t('Report_Activity_AllActivityType')}
                                    options={activityList}
                                    isClearable={true} 
                                    isSearchable={true}
                                    onChange={(e)=>{e !== null ? setDatID(e.value):setDatID("")}}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <FormSelect 
                                    placeholder={t('Report_Activity_AllCompletionStatus')}
                                    options={completionStatusList}
                                    isClearable={true}
                                    onChange={(e)=>{e !== null ? setMadVal(e.value):setMadVal("")}} 
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <FormSelect 
                                    placeholder={t('Report_AllPipeline')}
                                    options={pipeList}
                                    isClearable={true} 
                                    isSearchable={true}
                                    onChange={(e)=>{e !== null ? setPipelineID(e.value):setPipelineID("")}}
                                />
                            </Form.Group>
                        </section>
                        <section className="footer">
                            <div className="d-flex justify-content-center align-items-center w-100">
                                <button
                                    className="btn op-button op-primary-color text-light shadow me-2"
                                    onClick={() => {
                                    setTrigger(trigger + 1);
                                    }}
                                >
                                    {t('Report_ViewReport')}
                                </button>
                                <button className="btn btn-dark" onClick={handleDownload}>{t('Report_Download')}</button>
                            </div>
                        </section>
                    </div>
                    <div className="report-content">
                        <section className="w-100">
                            <Stack direction="horizontal">
                                {isReport && <div className="p-2 ms-auto">{Number(recordFound) === 1 ? t('Report_Record_one', {count: recordFound}) : Number(recordFound) > 1 ? t('Report_Record_other', {count: recordFound}) : t('Report_NoRecord')}</div>}
                            </Stack>
                        </section>
                        <section className="w-100">
                            <div style={containerStyle}>
                                <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyle }}>
                                    <AgGridReact
                                        ref={gridRef}
                                        columnDefs={reportColumn}
                                        datasource={reportDataSource}
                                        defaultColDef={reportColDef}
                                        rowSelection={"multiple"}
                                        rowModelType={"infinite"}
                                        rowHeight={70}
                                        cacheBlockSize={100}
                                        cacheOverflowSize={2}
                                        maxConcurrentDatasourceRequests={1}
                                        infiniteInitialRowCount={1}
                                        maxBlocksInCache={200}
                                        pagination={true}
                                        paginationPageSize={100}
                                        paginationPageSizeSelector={false}
                                        suppressRowClickSelection={true}
                                        animateRows={true}
                                    />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            )}
        </Container>
    );

}

export default ActivityReport;