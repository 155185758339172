import { useState, useRef, useEffect, useMemo, useCallback, forwardRef } from "react";
import { Row, Col, Stack, Card, Form, Modal, Nav, Dropdown } from "react-bootstrap";
import FormSelect from "../../includes/FormSelect";
import axios from "../../api/axios";
import { useAuth } from "../../auth/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2";
import * as formik from "formik";
import * as yup from "yup";
import { AgGridReact } from "ag-grid-react";
// import NoRecord from "../../includes/NoRecord";
import Loader from "../../includes/Loader";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/pro-duotone-svg-icons";
import { setCurrency } from "../../lib/js/Function";
import "../../lib/css/ClickAbleGrid.css";
import ReactHtmlParser from "html-react-parser";
import { faArrowRightArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { faInfo } from "@fortawesome/sharp-solid-svg-icons";

const LotDetails = ({ prospectID }) => {
    const init = useRef(false);
    const { session } = useAuth();
    const { Formik } = formik;

    const [prosData, setProsData] = useState();
    const [purLotRowData, setPurLotRowData] = useState([]);
    const [isPurLot, setIsPurLot] = useState(false);
    const [purLotRecFound, setPurLotRecFound] = useState(0);
    const [purLotHeight, setPurLotHeight] = useState("");
    const [isTransferLot, setIsTransferLot] = useState(false);
    // const [transferLotData, setTransferLotData] = useState({});

    const [purLotHisRowData, setPurLotHisRowData] = useState([]);
    const [isPurLotHis, setIsPurLotHis] = useState(true);
    const [purLotHisRecFound, setPurLotHisRecFound] = useState(0);

    const [selRowData, setSelRowData] = useState({});
    const [lotInfoData, setLotInfoData] = useState([]);
    const [activeTab, setActiveTab] = useState('contactDet');
    const [rrCity, setRRCity] = useState([]);
    const [rrCityList, setRRCityList] = useState([]);
    // const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);

    const [isEditSPA, setIsEditSPA] = useState(false);
    const [isLdSPA, setIsLdSPA] = useState(false);

    const [isEditNameID, setIsEditNameID] = useState(false);
    const [isLdNameID, setIsLdNameID] = useState(false);

    const [isEditCtcDet, setIsEditCtcDet] = useState(false);
    const [isLdCtcDet, setIsLdCtcDet] = useState(false);

    const [isEditDS, setIsEditDS] = useState(false);
    const [isLdEditDS, setIsLdEditDS] = useState(false);

    const [dsList, setDsList] = useState([]);

    const [rrUser, setRRUser] = useState("");
    const [userList, setUserList] = useState([]);
    const [teamList, setTeamList] = useState([]);

    const [isReserveModal, setIsReserveModal] = useState(false);
    const [reserveRowData, setReserveRowData] = useState([]);
    
    const reserveGridRef = useRef(null);
    const containerStyleReserve = useMemo(() => ({ width: "100%", height:  '50vh', paddingTop: 10 }), []);
    const gridStyleReserve = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const idTypeContactDet = [
        {label: "NRIC No.", value: "12512"},
        {label: "Passport", value: session.hostUrlType === 'cloud_staging' ? "358037" : "368259"},
        {label: "Reg No.", value: session.hostUrlType === 'cloud_staging' ? "358038" : "368260"},
        {label: "Other", value: "14816"},
    ];

    const contactPrefixLotDetails = [
        { label: "+60", value: "60" },
        { label: "+61", value: "61" },
        { label: "+65", value: "65" },
        { label: "+1", value: "1" },
        { label: "+27", value: "27" },
        { label: "+33", value: "33" },
        { label: "+41", value: "41" },
        { label: "+44", value: "44" },
        { label: "+49", value: "49" },
        { label: "+62", value: "62" },
        { label: "+63", value: "63" },
        { label: "+66", value: "66" },
        { label: "+81", value: "81" },
        { label: "+82", value: "82" },
        { label: "+84", value: "84" },
        { label: "+86", value: "86" },
        { label: "+90", value: "90" },
        { label: "+91", value: "91" },
        { label: "+92", value: "92" },
        { label: "+94", value: "94" },
        { label: "+353", value: "353" },
        { label: "+357", value: "357" },
        { label: "+673", value: "673" },
        { label: "+850", value: "850" },
        { label: "+852", value: "852" },
        { label: "+853", value: "853" },
        { label: "+880", value: "880" },
        { label: "+886", value: "886" },
        { label: "+966", value: "966" },
        { label: "+971", value: "971" },
        { label: "+973", value: "973" },
        { label: "+974", value: "974" },
        { label: "+996", value: "996" }
    ];

    const transferTypeList = [
        {label: 'Transfer Unit', value: 'transfer_unit'},
        {label: 'Transfer Contacts', value: 'transfer_contact'},
        {label: 'Transfer Open Deals', value: 'transfer_open_deal'}
    ]

    const purLotGridRef = useRef();
    const containerStylePurLot = useMemo(() => ({ width: "100%", height:  purLotHeight, paddingTop: 10 }), [isPurLot, purLotHeight]);
    const gridStylePurLot = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const reportColDef = useMemo(() => {
        return {
          sortable: true,
          unSortIcon: true,
          filter: false,
        };
    }, []);

    const handleTransferLot = () => {
        setIsTransferLot(true);
    }

    const [purLotColumn, setPurLotColumn]= useState([
        {
            // checkboxSelection: true,
            headerName: "Purchased Lots",
            resizable: true,
            flex: 1,
            minWidth: 260,
            comparator: (valueA, valueB) => {
                return valueA.localeCompare(valueB);
            },
            valueGetter: (params) => {
                if (params.data) {
                    return `${params.data.phase ? params.data.phase : ''} ${params.data.unit_name}`;
                }
            },
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <Stack direction="horizontal" gap={2}>
                            {session.hostUrlType === 'cloud_staging' && 
                                <button 
                                    type="button" 
                                    className="btn btn-light" 
                                    onClick={() => {triggerReserveModal(params.data)}}
                                >
                                    <FontAwesomeIcon icon={faInfo} size="lg"/>
                                </button>
                            }
                            <div className='clickable'>
                                {`${params.data.phase ? params.data.phase : ''} ${params.data.unit_name}`}
                            </div>
                        </Stack>
                        
                    ); 
                }
            }
        },
        {
            headerName: "Generation",
            field: "generation",
            resizable: true,
            width: 150,
            comparator: (valueA, valueB) => {
                return valueA.localeCompare(valueB);
            },
            valueGetter: (params) => {
                if (params.data) {
                    if (params.data.role === 'subsalesMainBuyer' || params.data.role === 'subsalesJointBuyer') {
                        if (Number(params.data.generation) === 1) {
                            return Number(params.data.generation) + 1;
                        }else{
                            return params.data.generation;
                        }
                    }else{
                        return params.data.generation
                    }
                }
            }
        },
        {
            headerName: "Role",
            resizable: true,
            width: 90,
            comparator: (valueA, valueB) => {
                return valueA.localeCompare(valueB);
            },
            valueGetter: (params) => {
                if (params.data) {
                    if (params.data.nominee === 'TRUE') {
                        return 'Nominee';
                    }else{
                        if (params.data.role == 'mainBuyer' || params.data.role == 'subsalesMainBuyer') {
                            return "Buyer";
                        }else if(params.data.role == 'jointBuyer' || params.data.role == 'subsalesJointBuyer'){
                            return "Joint Buyer";
                        }else{
                            return params.data.role;
                        }
                    }
                }
            }
        },
        {
            headerName: "Module Code",
            resizable: true,
            width: 140,
            comparator: (valueA, valueB) => {
                return valueA.localeCompare(valueB);
            },
            valueGetter: (params) => {
                if (params.data) {
                    if (params.data.pms_ownershipID) {
                        return "PMS";
                    }else{
                        return "PDS";
                    }
                }
            }
        },
        {
            headerName: "Status",
            field: "status",
            resizable: true,
            width: 100
        },
        {
            headerName: "Owner",
            field: "sales_personnel",
            resizable: true,
            width: 200,
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <Stack direction="horizontal" gap={3} className="w-100">
                            {params.data.status === 'Booked' && session.hostUrlType === 'cloud_staging' && <div>
                                <button className="btn btn-light shadow-sm border-1" onClick={() => {handleTransferLot()}}>
                                    <FontAwesomeIcon icon={faArrowRightArrowLeft} />
                                </button>
                            </div>}
                            <div>{params.data.sales_personnel}</div>
                        </Stack>
                    );
                }
            }
        },
        {
            headerName: "Created",
            resizable: true,
            width: 150,
            comparator: (date1, date2) => {
                
                const dateObj1 = new Date(date1);
                const dateObj2 = new Date(date2);

                if (dateObj1 < dateObj2) return -1;
                if (dateObj1 > dateObj2) return 1;
                return 0;
            },
            valueGetter: (params) => {
                if (params.data) {
                    if (params.data.created_date) {
                        return moment(params.data.created_date).format("lll");
                    }
                }
            }
        },
        {
            headerName: "Modified",
            resizable: true,
            width: 150,
            comparator: (date1, date2) => {
                
                const dateObj1 = new Date(date1);
                const dateObj2 = new Date(date2);

                if (dateObj1 < dateObj2) return -1;
                if (dateObj1 > dateObj2) return 1;
                return 0;
            },
            valueGetter: (params) => {
                if (params.data) {
                    if (params.data.modified_date) {
                        return moment(params.data.modified_date).format("lll");
                    }
                }
            }
        }
    ]);

    const getPurchaseLots = () => {
        setIsPurLot(false);
        axios.get('ext/gamuda/api_getPurchaseLots.php', {
            params:{
                prospect_id: prospectID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.record && data.record.length > 0) {
                setPurLotRecFound(data.record.length);
                setPurLotRowData(data.record);
                setIsPurLot(true);
                setPurLotHeight(Number(data.record.length) > 11 ? "52vh" : "30vh");
            }else{
                setPurLotRecFound(0);
                setPurLotRowData([]);
                setIsPurLot(true);
                setPurLotHeight("30vh");
            }
        })
        .catch(error => {
            setPurLotRecFound(0);
            setPurLotRowData([]);
            setIsPurLot(true);
            setPurLotHeight("30vh");
        });
    }

    const purLotHisGridRef = useRef();
    const containerStylePurLotHis = useMemo(() => ({ width: "100%", height: isPurLotHis ? "35vh" : "35vh", paddingTop: 5, paddingBottom: 10 }), [isPurLotHis]);
    const gridStylePurLotHist = useMemo(() => ({ height: "100%", width: "100%" }), []);

    const [purLotHisColumn] =  useState([
        {
            headerName: "Contact",
            field: "debtorName",
            resizable: true,
            width: 250,
            comparator: (valueA, valueB) => {
                return valueA.localeCompare(valueB);
            },
            valueGetter: (params) => {
                if (params.data) {
                    return ReactHtmlParser(params.data.debtorName);
                }
            },
            cellRenderer: (params) => {
                if (params.data) {
                    return(
                        <div 
                            className="clickable"
                            onClick={() => {window.open(session.origin+"/prospect/"+params.data.outperform_prospect_id,"_blank")}}
                        >
                            {ReactHtmlParser(params.data.debtorName)}
                        </div>
                    );
                }
            }
        },
        {
            headerName: "Role",
            field: "role",
            resizable: true,
            width: 100,
            valueGetter: (params) => {
                if (params.data) {
                    if (params.data.nominee === 'TRUE') {
                        return "nominee";
                    }else{
                        return params.data.role;
                    }
                }
            }
        },
        {
            headerName: "Generation",
            field: "generation",
            resizable: true,
            width: 150,
            valueGetter: (params) => {
                if (params.data) {
                    if (params.data.role === 'subsalesMainBuyer' || params.data.role === 'subsalesJointBuyer') {
                        if (Number(params.data.generation) === 1) {
                            return Number(params.data.generation) + 1;
                        }else{
                            return params.data.generation;
                        }
                    }else{
                        return params.data.generation;
                    }
                }
            }
        },
        {
            headerName: "Sequence (Sub-sale)",
            resizable: true,
            width: 200,
            comparator: (valueA, valueB) => {
                if (Number(valueA) < Number(valueB)) return -1;
                if (Number(valueA) > Number(valueB)) return 1;
                return 0;
            },
            valueGetter: (params) => {
                if (params.data) {
                    if (Number(params.data.generation) > 0) {
                        return Number(params.data.generation) - 1;
                    }else{
                        return 0;
                    }
                }
            }
        },
        {
            headerName: "Owner",
            field: "sales_personnel",
            resizable: true,
            width: 150
        },
        {
            headerName: "Created On",
            resizable: true,
            width: 150,
            comparator: (date1, date2) => {
                const dateObj1 = new Date(date1);
                const dateObj2 = new Date(date2);

                if (dateObj1 < dateObj2) return -1;
                if (dateObj1 > dateObj2) return 1;
                return 0;
            },
            valueGetter: (params) => {
                if (params.data) {
                    if (params.data.salesDate) {
                        return moment( new Date(params.data.salesDate) ).format("ll");
                    }
                }
            }
        },
        {
            headerName: "Modified On",
            resizable: true,
            width: 150,
            comparator: (date1, date2) => {
                const dateObj1 = new Date(date1);
                const dateObj2 = new Date(date2);

                if (dateObj1 < dateObj2) return -1;
                if (dateObj1 > dateObj2) return 1;
                return 0;
            },
            valueGetter: (params) => {
                if (params.data) {
                    if (params.data.date_time_create) {
                        return moment( new Date(params.data.date_time_create) ).format("ll");
                    }
                }
            }
        }
    ]);

    const getPurchaseLotInfo = (salesID, pms_ownershipID, subSalesID) => {
        if (salesID) {
            
            axios.get('ext/gamuda/api_getPurchaseLotInfoV2.php', {
                params:{
                    salesID: salesID,
                    prospect_id: prospectID,
                    pms_ownershipID: pms_ownershipID,
                    subSalesID: subSalesID,
                    utoken: session.user_ac_token,
                    ctoken: session.company_token
                }
            })
            .then(res => {
                let data = res.data;

                if (data.record.status == 0) {
                    setLotInfoData(data.record);

                    var rowData1 = data.record.purchaseHistory.mainBuyerPurchase ? data.record.purchaseHistory.mainBuyerPurchase.map(data => {return data}) : [];
                    var rowData2 = data.record.purchaseHistory.jointBuyerPurchase ? data.record.purchaseHistory.jointBuyerPurchase.map(data => {return data}) : [];
                    var rowData3 = data.record.purchaseHistory.subsalesPurchase ? data.record.purchaseHistory.subsalesPurchase.map(data => {return data}) : [];

                    var completeRowData = [...rowData1, ...rowData2, ...rowData3];

                    setPurLotHisRecFound(completeRowData.length);
                    setPurLotHisRowData(completeRowData);

                }else{
                    setLotInfoData([]);
                    setPurLotHisRecFound(0);
                    setPurLotHisRowData([]);
                }
            })
            .catch(error => {
                setLotInfoData([]);
                setPurLotHisRecFound(0);
                setPurLotHisRowData([]);
            });

        }
    }

    const getProspectDetail = () => {

        axios.get('ws/ws_prospect_view.php', {
            params:{
                task: "viewProspectDetailsV2",
                prospect: prospectID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                setProsData(data.record);
            }else{
                setProsData([]);
            }
        })
        .catch(error => {
            console.log(error);
            setProsData([]);
        });
    }

    const listCityRR = async (id) => {
        var params = new FormData();

        params.append("task", "listCity");

        try {
            var response = await axios.post("ext/gamuda/ws_listcity.php", params);

            let data = response.data;

            if (data) {
                var list = data.map(info => {
                    return {label: info.rr_value, value: info.rr_id, op_city_id: info.outperform_city_id};
                });

                list.sort((a, b) => a.label.localeCompare(b.label));

                setRRCityList(list);

                var list2 = data.map(data => {
                    return data;
                });

                setRRCity(list2);
                getCountryLotDet(id, data);
            }else{
                setRRCity([]);
            }
        } catch (error) {
            setRRCity([]);
        }
    }

    // const getCountry = () => {
        
    //     axios.get('ws/ws_listplace.php', {
    //         params: {
    //             task: "listCountry",
    //             utoken: session.user_ac_token,
    //             ctoken: session.company_token
    //         }
    //     })
    //     .then(res => {
    //         let data = res.data;

    //         if (data.record) {
    //             var countryList = data.record.map((info) => {
    //                 return {label: info.country_title, value: info.country_id};
    //             });
                
    //             setCountryData(countryList);
    //         }else{
    //             setCountryData([]);
    //         }
    //     })
    //     .catch(error => {
    //         setCountryData([]);
    //     });
    // }

    const getState = () => {

        axios.get('ws/ws_listplace.php', {
            params: {
                task: "listState",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.record) {
                var stateList = data.record.map((info) => {
                    return {label: info.state_title, value: info.state_id, country: info.country_id};
                });

                setStateData(stateList);
            }else{
                setStateData([]);
            }
        })
        .catch(error => {
            setStateData([]);
        });
    }

    const getCity = () => {

        axios.get('ws/ws_listplace.php', {
            params: {
                task: "listCityGL",
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (data.record) {
                var stateList = data.record.map((rec) => {
                    return {label: rec.city_title, value: rec.city_id, state: rec.state_id};
                });

                setCityData(stateList);
            }else{
                setCityData([]);
            }
        })
        .catch(error => {
            setCityData([]);
        });
    }

    const [countryLotDet, setCountryLotDet] = useState("");

    const getCountryLotDet = (id, data) => {
        var opCityID = "";
        data.foreach(data => {
            if (data.rr_id == id) {
                opCityID = data.outperform_city_id;
            }
        });

        axios.get("ext/gamuda/ws_listcity.php", {
            params:{
                task: "getStateAndCountry",
                outperform_city_id: opCityID,
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == "0") {
                setCountryLotDet(data.countryTitle);
            }else{
                setCountryLotDet("");
            }
        })
        .catch(error => {
            setCountryLotDet("");
        });
    }

    const getAllDS = () => {

        axios.get("ws/ws_source.php", {
            params:{
                task: 2,
                company: session.company_id,
                disp: "show"
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                var dataList = data.record.map(data => {
                    return {label: data.ds_title, value: data.ds_id};
                });

                setDsList(dataList);
            }else{
                setDsList([]);
            }
        })
        .catch(error => {
            setDsList([]);
        });
    }
    
    const firstRender = useCallback((params) => {
        var nodesToSelect = [];
        
        params.api.forEachNode((node, index) => {
            if (Object.keys(selRowData).length > 0) {
                if (selRowData.salesID == node.data.salesID) {
                    nodesToSelect.push(node);
                }
            }else if (index == 0) {
                nodesToSelect.push(node);
            }
        });
        
        params.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
    }, [selRowData]);

    const handleRowSelect = useCallback(() => {
        
        var selectedRow = purLotGridRef.current.api.getSelectedRows();
        
        getPurchaseLotInfo(selectedRow[0].salesID, selectedRow[0].pms_ownershipID, selectedRow[0].subSalesID);
        listCityRR(selectedRow[0].city_id);
        var emailSplit = selectedRow[0].email.split(",");

        selectedRow[0].email1 = emailSplit[0] ? emailSplit[0] : "";
        selectedRow[0].email2 = emailSplit[1] ? emailSplit[1] : "";

        var addressSplit = selectedRow[0].address.split("\r\n");

        selectedRow[0].address1 = addressSplit[0] ? addressSplit[0] : "";
        selectedRow[0].address2 = addressSplit[1] ? addressSplit[1] : "";
        selectedRow[0].address3 = addressSplit[2] ? addressSplit[2] : "";

        var prefix = "";
        var phone1 = "";
        var prefix2 = "";
        var phone2 = "";

        if (selectedRow[0].phone1) {
            prefix = contactPrefixLotDetails.filter(opt => selectedRow[0].phone1.replaceAll('+', '').startsWith(opt.value));
        }

        if (prefix.length > 0) {
            if (prefix[0].value == '60') {
                phone1 = "0" + selectedRow[0].phone1.substring(prefix[0].value.length);
            }else{
                phone1 = selectedRow[0].phone1.substring(prefix[0].value.length);
            }
        }else{
            phone1 = selectedRow[0].phone1;
        }

        if (selectedRow[0].phone2) {
            prefix2 = contactPrefixLotDetails.filter(opt => selectedRow[0].phone2.replaceAll('+', '').startsWith(opt.value));
        }

        if (prefix2.length > 0) {
            if (prefix2[0].value == "60") {
                phone2 = "0" + selectedRow[0].phone2.substring(prefix2[0].value.length);
            }else{
                phone2 = selectedRow[0].phone2.substring(prefix2[0].value.length);
            }
        }else{
            phone2 = selectedRow[0].phone2;
        }
        
        selectedRow[0].phone1Pref = prefix.length > 0 ? prefix[0].value : "";
        selectedRow[0].phone1Num = phone1;

        selectedRow[0].phone2Pref = prefix2.length > 0 ? prefix2[0].value : "";
        selectedRow[0].phone2Num = phone2;

        setSelRowData(selectedRow[0]);
    }, [selRowData]);

    const [reserveColumn] = useState([
        {
            headerName: 'Purchased Lots',
            field: '',
            resizable: true,
            width: 250
        },
        {
            headerName: 'Generation',
            field: '',
            resizable: true,
            width: 250
        },
        {
            headerName: 'Role',
            field: '',
            resizable: true,
            width: 250
        },
        {
            headerName: 'Module Code',
            field: '',
            resizable: true,
            width: 250
        },
        {
            headerName: 'Status',
            field: '',
            resizable: true,
            width: 250
        },
        {
            headerName: 'Owner',
            field: '',
            resizable: true,
            width: 250
        }
    ]);

    const triggerReserveModal = (data) => {
        setReserveRowData([]);
        setIsReserveModal(session.hostUrlType === 'cloud_staging' ? true : false);
    }

    const getRRUser = () => {
        axios.get('ext/gamuda/api_rr_mapRRUser.php',{
            params:{
				outperform_user_id: session.user_id
			}
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                setRRUser(data.rrUser);
            }else{
                setRRUser("");
            }
        })
        .catch(error => {
            setRRUser("");
        });
    }

    const getAllUser = () => {
        axios.get('ws/ws_user.php', {
            params: {
                task: '4a',
                user_tag: '',
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var dataList = data.record.map(rec => {
                    return {label: rec.user_name, value: rec.user_id};
                });

                setUserList(dataList);
            }else{
                setUserList();
            }
        })
        .catch(error => {
            console.log(error);
            setUserList([]);
        });
    }

    const getAllTeam = () => {
        axios.get('ws/ws_user.php', {
            params: {
                task: 'getUserTags',
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                var dataList = data.record.map((rec) => {
                    return {label: rec.user_tag_title, value: rec.user_tag_id};
                });

                setTeamList(dataList);
            }else{
                setTeamList([]);
            }
        })
        .catch(error => {
            console.log(error);
            setTeamList([]);
        });
    }

    useEffect(() => {
        if (!init.current) {
            init.current = true;
            getPurchaseLots();
            getRRUser();
            getAllDS();
            getCity();
            getState();
            getProspectDetail();
            getAllUser();
            getAllTeam();
            // getCountry();
        }
    }, []);

    const onchangeActionTabs = (key) => {
        setActiveTab(key);
    }

    const RenderEditContactDet = () => {

        const CustomToggle = forwardRef(({ onClick }, ref) => {
            const openDropdown = (e) => {
              e.preventDefault();
              onClick(e);
            };
      
            return (
              <button ref={ref} onClick={openDropdown} disabled={disabledCtcDet(selRowData)} className="btn btn-light border">
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
            );
        });

        return(
            <Dropdown size={100} className="ms-auto">
                <Dropdown.Toggle as={CustomToggle} />
                <Dropdown.Menu className="shadow-sm border-none animate slideIn" align="end" size={100}>
                    {session.role_id == 1 && <Dropdown.Item onClick={setIsEditNameID}>Edit Full Name & ID No.</Dropdown.Item>}
                    <Dropdown.Item onClick={setIsEditCtcDet}>Edit Contact Details</Dropdown.Item>
                    {selRowData != 'Signed' && <Dropdown.Item onClick={setIsEditDS}>Edit Deal Source</Dropdown.Item>}
                </Dropdown.Menu>
            </Dropdown>
        );

    }

    const RenderTab = ({tab}) => {
        switch (tab) {
            case 'contactDet':
                
                return(
                    <Card.Body className="p-0">
                        <div className="py-0 px-4 mt-3">
                            <Card className="rounded border-1 mx-2">
                                <Card.Header className="rounded border-0" style={{backgroundColor: "#fff", padding: 0}}>
                                    <div style={{padding: "10px 15px"}}>
                                        <Stack direction="horizontal" gap={1}>
                                            <h6 className="mt-2">Contact Details</h6>
                                            <RenderEditContactDet />
                                        </Stack>
                                    </div>
                                </Card.Header>
                            </Card>
                        </div>

                        <Row className="px-4">
                            <Col sm={6} className="p-2">
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Full Name</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.debtorName ? ReactHtmlParser(selRowData.debtorName) : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Identification Type</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>
                                                {selRowData.debtorIDType == 358038 ? "Reg No." : selRowData.debtorIDType == 12512 ? "NRIC No." : selRowData.debtorIDType == 14816 ? "Others" : selRowData.debtorIDType == 358037 ? "Passport" : "-"}
                                            </Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Identification No 1 (Newest)</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.debtorIDNo ? selRowData.debtorIDNo : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Contact Number 1</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.phone1 ? selRowData.phone1 : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Contact Number 2</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.phone2 ? selRowData.phone2 : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Email 1</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.email1 ? selRowData.email1 : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Email 2</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.email2 ? selRowData.email2 : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Source Category</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.source_category_title ? selRowData.source_category_title : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Source</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.ds_title ? selRowData.ds_title : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Nominee</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>
                                                {selRowData.nominee == "TRUE" ? "Yes" : selRowData == "FALSE" ? "No" : "-"}
                                            </Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={6} className="p-2">
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Address Line 1</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.address1 ? selRowData.address1 : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Address Line 2</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.address2 ? selRowData.address2 : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Address Line 3</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.address3 ? selRowData.address3 : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Town/City</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.city ? selRowData.city : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Postcode</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{selRowData.postcode ? selRowData.postcode : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                                <Card className="border-0 mb-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Country</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{countryLotDet ? countryLotDet : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                );
            
            case 'purLotHis':

                return(
                    <Card.Body>
                        <div style={containerStylePurLotHis}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStylePurLotHist }}>
                                <AgGridReact
                                    ref={purLotHisGridRef}
                                    columnDefs={purLotHisColumn}
                                    rowData={purLotHisRowData}
                                    defaultColDef={reportColDef}
                                    rowHeight={50}
                                    rowSelection="single"
                                />
                            </div>
                        </div>
                    </Card.Body>
                );

            case 'solicitor':

                return(
                    <Card.Body className="pt-1">
                        <Row className="my-2">
                            <Col sm={12} className="mb-0">
                                <Card className="border-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Company Name</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{lotInfoData.spaDetails ? lotInfoData.spaDetails.solicitorName : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12} className="mb-0">
                                <Card className="border-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Contact Number</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{lotInfoData.spaDetails ? lotInfoData.spaDetails.solicitorContactNum : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card className="border-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Address</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{lotInfoData.spaDetails ? lotInfoData.spaDetails.solicitorAddress : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                );

            case 'financier':

                return(
                    <Card.Body className="pt-1">
                        <Row className="my-2">
                            <Col sm={12} className="mb-0">
                                <Card className="border-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Bank Name</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{!lotInfoData.spaDetails ? "-" : lotInfoData.spaDetails.financierName && lotInfoData.spaDetails.financierName !== '-' ? `${lotInfoData.spaDetails.financierName} (${lotInfoData.spaDetails.financierBranch})` : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12} className="mb-0">
                                <Card className="border-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Branch Contact Number</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{!lotInfoData.spaDetails ? "-" : lotInfoData.spaDetails.financierPicContact ? lotInfoData.spaDetails.financierPicContact : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card className="border-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Branch Address</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>{!lotInfoData.spaDetails ? "-" : lotInfoData.spaDetails.financierBranchAddress ? lotInfoData.spaDetails.financierBranchAddress : "-"}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                );

            case 'vpKey':

                return(
                    <Card.Body className="pt-1">
                        <Row className="my-2">
                            <Col sm={12} className="mb-0">
                                <Card className="border-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">VP Date</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>
                                                {!lotInfoData.vpKey ? "-" : new Date(lotInfoData.vpKey.vp_date).getTime() ? moment( new Date(lotInfoData.vpKey.vp_date) ).format("ll") : "-"}
                                            </Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card className="border-0">
                                    <Card.Body>
                                        <div>
                                            <Card.Title as={"p"} className="fw-bold">Key Collection Date</Card.Title>
                                            <Card.Text style={{fontSize: 13}}>
                                                {!lotInfoData.vpKey ? "-" : new Date(lotInfoData.vpKey.key_collection_date_time).getTime() ? moment( new Date(lotInfoData.vpKey.key_collection_date_time) ).format("ll") : "-"}
                                            </Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                );

            default:
                return null;
        }
    }

    const editSPASchema = yup.object().shape({
        spaDate: yup.string().required("This field is required")
    });

    const sendEditSPA = (values) => {
        setIsLdSPA(true);

        axios.get("ext/gamuda/api_rr_spaupdate.php", {
            params:{
                salesID: values.salesID,
                spaDate: values.spaDate
            }
        })
        .then(res => {
            let data =  res.data;

            if (data.IsSuccess == true) {
                if (data.Data[0].IsSuccess == 1) {
                    Swal.fire({
                        icon: "success",
                        title: data.Data[0].Message,
                        timer: '1500'
                    })
                    .then(result => {
                        setIsLdSPA(false);
                        setIsEditSPA(false);
                        getPurchaseLots();
                    });
                }else{
                    Swal.fire({
                        icon: "error",
                        title: data.Data[0].Message
                    })
                    .then(result => {
                        setIsLdSPA(false);
                    });
                }
            }else{
                Swal.fire({
                    icon: "error",
                    title: data.Message
                })
                .then(result => {
                    setIsLdSPA(false);
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(result => {
                setIsLdSPA(false);
            });
        });
    };

    const editCtcDetNameIDSchema = yup.object().shape({
        fullName: yup.string().required("This field is required"),
        idType: yup.string().required("This field is required"),
        idNum: yup.string().required("This field is required")
    });

    const sendEditCtcDetNameID = (values) => {
        setIsLdNameID(true);
        
        axios.get('ext/gamuda/api_rr_update_debtor_name.php', {
            params:{
                debtorName: values.fullName,
                debtorIdNo: values.idNum,
                debtorID: values.debtorID,
                salesID: values.salesID,
                role: values.role,
                idType: values.idType,
                prospect_id: prospectID,
                user_id: session.user_id,
                company_id: session.company_id
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Successfully Updated",
                    timer: "1500"
                })
                .then(result => {
                    setIsLdNameID(false);
                    setIsEditNameID(false);
                    getPurchaseLots();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: 'Failed',
                    text: data.message
                })
                .then(result => {
                    setIsLdNameID(false);
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(result => {
                setIsLdNameID(false);
            });
        });
    };

    const editCtcDetSchema = yup.object().shape({
        phone1Pref: yup.string().required("This field is required"),
        phone1Num: yup.number().typeError("Must be a number type").required("This field is required"),
        phone2Num: yup.number().typeError("Must be a number type"),
        email1: yup.string().email('must be valid email').required("This field is required"),
        email2: yup.string().email('must be valid email'),
        address1: yup.string().required("This field is required"),
        address2: yup.string().required("This field is required"),
        cityID: yup.string().required("This field is required"),
        postcode: yup.string().required("This field is required")
    });

    const sendEditCtcDet = (values) => {
        setIsLdCtcDet(true);
        
        if (values.contact_level) {
            handleContactLvl(values);
        }else{
            handleContactDetRR(values);
        }
        
        
    }

    const handleContactLvl = (values) => {
        var opCountryID = "";
        var opStateID = "";
        var opCityID = "";

        rrCityList.forEach(data => {
            if (Number(data.value) === Number(values.cityID)) {
                opCityID = data.op_city_id;
            }
        });

        cityData.forEach(data => {
            if (data.value === opCityID) {
                opStateID = data.state;
            }
        });

        stateData.forEach(data => {
            if (data.value === opStateID) {
                opCountryID = data.country
            }
        });

        axios.get('ws/ws_edit_prospect_details.php', {
            params: {
                task: "updateContacts",
                prospect_id: prospectID,
                id_no: values.debtorIDNo,
                contact1_prefix: values.phone1Pref,
                contact1: values.phone1Num,
                contact1_old: prosData.contact1,
                contact2_prefix: values.phone2Pref,
                contact2: values.phone2Num,
                contact3_prefix: "",
                contact3: "",
                contact4_prefix: "",
                contact4: "",
                email1: values.email1,
                email2: "",
                address_type: prosData.address_type,
                address1: values.address1,
                address2: values.address2,
                address3: values.address3,
                state_id: opStateID,
                city_id: opCityID,
                district_id: 0,
                postcode: values.postcode,
                country_id: opCountryID,
                utoken: session.user_ac_token,
                ctoken: session.company_token
            }
        })
        .then(res => {
            let data = res.data;

            if (Number(data.status) === 0) {
                handleContactDetRR(values);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Failed',
                    text: 'Please try again or contact your IT Support'
                });
            }
        });
    }

    const handleContactDetRR = (values) => {
        axios.get('ext/gamuda/api_rr_buyerprofileedit.php', {
            params:{
                debtorID: values.debtorID,
                salesID: values.salesID,
                debtorName: values.fullName,
                contact1: values.phone1Num,
                contact1_prefix: values.phone1Pref,
                contact2: values.phone2Num,
                contact2_prefix: values.phone2Pref,
                email1: values.email1,
                address_line1: values.address1,
                address_line2: values.address2,
                address_line3: values.address3,
                city: values.cityID,
                postal_code: values.postcode,
                role: values.role,
                company_id: session.company_id,
                user_id: session.user_id
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Contact Detail Updated",
                    timer: 1500
                })
                .then(result => {
                    setIsLdCtcDet(false);
                    setIsEditCtcDet(false);
                    getPurchaseLots();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: data.message
                })
                .then(res => {
                    setIsLdCtcDet(false);
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(res => {
                setIsLdCtcDet(false);
            });
        });
    }

    const editDsSchema = yup.object().shape({
        ds_id: yup.string().required("This field is required")
    });

    const sendEditDS = (values) => {
        setIsLdEditDS(true);

        axios.get('ext/gamuda/api_rr_sourceedit.php',{
            params:{
                salesID: values.salesID,
                debtorID: values.debtorID,
                dealSource: values.ds_id,
                prospect_id: prospectID,
                dealID: values.dealID
            }
        })
        .then(res => {
            let data = res.data;

            if (data.status == 0) {
                Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Successfully updated deal source",
                    timer: 1500
                })
                .then(result => {
                    setIsLdEditDS(false);
                    setIsEditDS(false);
                    getPurchaseLots();
                });
            }else{
                Swal.fire({
                    icon: "error",
                    title: "Failed",
                    text: data.message
                })
                .then(result => {
                    setIsLdEditDS(false);
                });
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                title: error.message
            })
            .then(result => {
                setIsLdEditDS(false);
            });
        });
        
    }

    const disableEditLotInfo = (data) => {
        if (Object.keys(data).length > 0) {
            if (session.role_id == 1) {
            
                if (data.role == "jointBuyer" || data.role == "subsalesJointBuyer" || data.role == "subsalesMainBuyer") {
                    return true;
                }else{
    
                    if (data.status == "Forfeited" || data.status == "Cancelled" || data.status == "Signed") {
                        return true;
                    }else{
                        return false
                    }
    
                }
    
            }else{
    
                if (data.role == "jointBuyer") {
                    return true;
                }else{
    
                    if (data.status == "Forfeited" || data.status == "Cancelled" || data.status == "Signed") {
                        return true;
                    }else{
                        return false;
                    }
    
                }
    
            }
        }else{
            return true;
        }
    }

    const disabledCtcDet = (data) => {
        if (Object.keys(data).length > 0) {
            if (session.role_id == 1) {
                if (data.role == 'jointBuyer') {
                    if (data.status == "Forfeited" || data.status == "Cancelled") {
                        return true;
                    }else{
                        return false;
                    }
                }else if (data.role == 'subsalesMainBuyer' || data.role == 'subsalesJointBuyer') {
                    return true;
                }else {
                    if (data.status == "Forfeited" || data.status == "Cancelled") {
                        return true;
                    }else{
                        return false;
                    }
                }
            }else{
                    
                if (data.status == "Forfeited" || data.status == "Cancelled" || data.status == "Signed") {
                    return true;
                }else{
                    return false;
                }
    
            }
        }else{
            return true;
        }
    }

    const disabledSalesMain = (data) => {
        if (Object.keys(data).length > 0) {
            if (session.role_id == 1) {
            
                if (data.role == "jointBuyer" || data.role == 'subsalesMainBuyer' || data.role == 'subsalesJointBuyer') {
                    return true;
                }else{
    
                    if (data.status == "Forfeited" || data.status == "Cancelled" || data.status == "Signed") {
                        return true;
                    }else{
                        return false;
                    }
    
                }
    
            }else{
    
                if (data.role == 'jointBuyer') {
                    return true;
                }else{
                    if (data.status == "Forfeited" || data.status == "Cancelled" || data.status == "Signed") {
                        return true;
                    }else{
                        return false;
                    }
                }
    
            }
        }else{
            return true;
        }
    }

    const disabledLoanApp = (data) =>{
        if (Object.keys(data).length > 0) {
            if (session.role_id == 1) {
            
                if (data.role == "jointBuyer" || data.role == 'subsalesMainBuyer' || data.role == 'subsalesJointBuyer') {
                    return true;
                }else{
    
                    if (data.status == "Forfeited" || data.status == "Cancelled" || data.status == "Signed") {
                        return true;
                    }else{
                        return false;
                    }
    
                }
    
            }else{
    
                if (data.role == 'jointBuyer') {
                    return true;
                }else{
                    if (data.status == "Forfeited" || data.status == "Cancelled" || data.status == "Signed") {
                        return true;
                    }else{
                        return false;
                    }
                }
    
            }
        }else{
            return true;
        }
    }

    const handleSaleMain = (data) => {

        if (session.hostUrlType != 'cloud_staging') {
            window.open(
                `rrRoadRunner://navigate?Start&code=MBSM&username=${rrUser}&accesscode=Gamuda@React2023&companyid=${data.company_id}&TableId17=${data.phase_id}&TC1TableId1=${data.unit_id}&TableId2=${data.unit_id}`,"_blank"
            );
        }else{
            window.open(
                `rrRoadRunner://navigate?Start&code=MBSM&username=${rrUser}&accesscode=react@123&companyid=${data.company_id}&TableId17=${data.phase_id}&TC1TableId1=${data.unit_id}&TableId2=${data.unit_id}`,"_blank"
            );
        }
    }

    const handleLoanApp = (data) => {
        if (session.hostUrlType === 'cloud_staging') {
            window.open(`rrRoadRunner://navigate?Start&code=MBSLA&username=${rrUser}&accesscode=react@123&companyid=${data.company_id}&Tableid2=${data.phase_id}&Tableid3=${data.unit_id}`, '_blank');
        }else{
            window.open(`rrRoadRunner://navigate?Start&code=MBSLA&username=${rrUser}&accesscode=Gamuda@React2023&companyid=${data.company_id}&Tableid2=${data.phase_id}&Tableid3=${data.unit_id}`, '_blank');
        }
    }

    const handlePrint = (data) => {
        if (session.hostUrlType === 'cloud_staging') {
            window.open(`rrRoadRunner://navigate?Start&code=MBSR&username=${rrUser}&accesscode=react@123&companyid=${data.company_id}&TableId17=${data.phase_id}&TC1TableId1=${data.unit_id}&TableId2=${data.unit_id}`, "_blank");
        }else{
            window.open(`rrRoadRunner://navigate?Start&code=MBSR&username=${rrUser}&accesscode=Gamuda@React2023&companyid=${data.company_id}&TableId17=${data.phase_id}&TC1TableId1=${data.unit_id}&TableId2=${data.unit_id}`, "_blank");
        }
    }

    const handlePushUnit = (data, movement) => {
        Swal.fire({
            icon: 'warning',
            title: `Are you sure to ${movement} this unit?`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel"
        })
        .then(result => {
            if (result.isConfirmed) {
                axios.get('ext/gamudaLifestyle/gllapi_update_lot_movement', {
                    params: {
                        Outperform_ID: data.outperform_prospect_id,
                        Sales_ID: data.salesID,
                        pms_ownershipID: data.pms_ownershipID,
                        Sub_Sales_ID: data.subSalesID,
                        Movement: movement,
                        execute: "yes"
                    }
                })
                .then(res => {
                    let data = res.data;
        
                    if (Number(data.status) === 0) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: data.lifestyle_response
                        })
                        .then(() => {
                            getPurchaseLots();
                        });
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: 'Failed',
                            text: data.lifestyle_response
                        });
                    }
                })
                .catch(error => {
                    console.log(error.message);
                });
            }
        });
    }

    const transferLotSchema = yup.object().shape({
        user_id: yup.string().required('This field is required'),
        team_id: yup.string().required('This field is required'),
        transfer_type: yup.string().required('This field is required')
    });

    const sendTransferLot = (values) => {
        console.log(selRowData);
        console.log(values);
    }

    return(
        <>
            {
                !isPurLot ? (<Loader />) : (
                    <div className="px-3">
                        {/* {selRowData.status === 'Booked' && <div className="mt-2">
                            <button className="btn btn-light shadow-sm border-1" onClick={() => {handleTransferLot(selRowData)}}>
                                <FontAwesomeIcon icon={faArrowRightArrowLeft} size="lg"/>
                            </button>
                        </div>} */}
                        <div style={containerStylePurLot}>
                            <div className={"ag-theme-quartz ag-op"} style={{ ...gridStylePurLot }}>
                                <AgGridReact
                                    ref={purLotGridRef}
                                    columnDefs={purLotColumn}
                                    rowData={purLotRowData}
                                    defaultColDef={reportColDef}
                                    rowHeight={40}
                                    rowSelection="single"
                                    onFirstDataRendered={firstRender}
                                    onSelectionChanged={handleRowSelect}
                                />
                            </div>
                        </div>

                        <Row className="mt-3">
                            <Col sm={6} className="mb-3">
                                <Card className="rounded border-0 shadow-sm">
                                    <Card.Header style={{backgroundColor: "#fff", padding: 0}}>
                                        <div style={{padding: "10px 20px"}}>
                                            <Stack direction="horizontal" gap={1}>
                                                <h6 className="mt-2">Lot Information</h6>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-light border ms-auto"
                                                    onClick={setIsEditSPA}
                                                    disabled={disableEditLotInfo(selRowData)}
                                                >
                                                    <FontAwesomeIcon icon={faPenToSquare} />
                                                </button>
                                                {
                                                    (selRowData.status == 'Signed' && (selRowData.role == 'mainBuyer' || selRowData.role == 'jointBuyer') && selRowData.pms_ownershipID == '') &&
                                                    
                                                    <button
                                                        type="button"
                                                        className="btn btn-light border"
                                                        onClick={() => {handlePrint(selRowData)}}
                                                    >
                                                        Print
                                                    </button>
                                                }
                                                {   
                                                    (selRowData.status === 'Signed' && (Number(session.user_id) === 1031 || Number(session.user_id) === 1545 || Number(session.user_id) === 3583 || Number(session.user_id) === 2271 || Number(session.user_id) === 1544)) &&
                                                    <button
                                                        type="button"
                                                        className="btn btn-success"
                                                        onClick={() =>{handlePushUnit(selRowData, 'Update')}}
                                                    >
                                                        Push Unit
                                                    </button>
                                                }
                                                <button
                                                    className="btn op-button op-primary-color text-light"
                                                    disabled={disabledSalesMain(selRowData)}
                                                    onClick={() => handleSaleMain(selRowData)}
                                                >
                                                    Sales Maintenance
                                                </button>
                                                <button
                                                    className="btn btn-light border shadow-sm"
                                                    disabled={disabledLoanApp(selRowData)}
                                                    onClick={() => {handleLoanApp(selRowData)}}
                                                >
                                                    Loan Application
                                                </button>
                                            </Stack>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                        <Row className="py-0 px-4">
                                            <Col sm={6} className="p-2">
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">Country Code</Card.Title>
                                                            {Object.keys(selRowData).length > 0 && <Card.Text style={{fontSize: 13}}>Malaysia</Card.Text>}
                                                            {Object.keys(selRowData).length === 0 && <Card.Text style={{fontSize: 13}}>-</Card.Text>}
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">Project Name</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{selRowData.project ? selRowData.project : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">Phase Name</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{selRowData.phase ? selRowData.phase : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">Phase Code</Card.Title>
                                                            {!selRowData.phase_id && !selRowData.pms_phase_id ? 
                                                                <Card.Text style={{fontSize: 13}}>-</Card.Text> 
                                                                : 
                                                                <Card.Text style={{fontSize: 13}}>{selRowData.pms_ownershipID == '' ? selRowData.phase_id : selRowData.pms_phase_id }</Card.Text>
                                                            }
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">Lot Unit</Card.Title>
                                                            {!selRowData.unit_name && !selRowData.pms_unit_name ? 
                                                                <Card.Text style={{fontSize: 13}}>-</Card.Text> 
                                                                : 
                                                                <Card.Text style={{fontSize: 13}}>{selRowData.pms_ownershipID == '' ? selRowData.unit_name : selRowData.pms_unit_name}</Card.Text>
                                                            }
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">Module Code</Card.Title>
                                                            {Object.keys(selRowData).length > 0 && <Card.Text style={{fontSize: 13}}>{selRowData.pms_ownershipID == '' ? "PDS" : "PMS"}</Card.Text>}
                                                            {Object.keys(selRowData).length === 0 && <Card.Text style={{fontSize: 13}}>-</Card.Text>}
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">Project Type</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>-</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col sm={6} className="p-2">
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">Sales Date</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{selRowData.salesDate ? moment( new Date( selRowData.salesDate ) ).format("ll") : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">Project Print Name</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{selRowData.project_print_name ? selRowData.project_print_name : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">Phase Print Name</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{selRowData.phase_print_name ? selRowData.phase_print_name : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">SPA Date</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{lotInfoData.spaDetails ? moment( new Date( lotInfoData.spaDetails.spaDate ) ).format("ll") : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">SPA Stamped Date</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{!lotInfoData.spaDetails ? "-" : new Date(lotInfoData.spaDetails.spaStampDate).getTime() ? moment( new Date(lotInfoData.spaDetails.spaStampDate) ).format("ll") : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">Sales Status</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{selRowData.status ? selRowData.status : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="border-0 mb-0">
                                                    <Card.Body>
                                                        <div>
                                                            <Card.Title as={"p"} className="fw-bold">SPA Price</Card.Title>
                                                            <Card.Text style={{fontSize: 13}}>{lotInfoData.spaDetails ? setCurrency(lotInfoData.spaDetails.spaPrice) : "-"}</Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>             
                            </Col>
                            <Col sm={6}>
                                <Card className="rounded border-0 shadow-sm mb-4">
                                    <Card.Header className="d-flex justify-content-between align-items-center" style={{backgroundColor: "#fff"}}>
                                        <Nav variant="underline" defaultActiveKey="contactDet" onSelect={onchangeActionTabs}>
                                            <Nav.Item>
                                                <Nav.Link eventKey="contactDet">
                                                    Contact Details
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="purLotHis">
                                                    Purchase Lot Histories
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="solicitor">
                                                    Solicitor Detail
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="financier">
                                                    Financiers Detail
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="vpKey">
                                                    VP Key Collection
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Card.Header>
                                    <RenderTab tab={activeTab}/>
                                </Card>
                            </Col>
                        </Row>

                        <Modal show={isEditSPA} onHide={setIsEditSPA}>
                            <Formik
                                validationSchema={editSPASchema}
                                onSubmit={sendEditSPA}
                                initialValues={
                                    {
                                        salesID: selRowData.salesID,
                                        spaDate: !lotInfoData.spaDetails ? "" : new Date(lotInfoData.spaDetails.spaDate).getTime() ? moment( new Date(lotInfoData.spaDetails.spaDate) ).format("YYYY-MM-DD") : ""
                                    }
                                }
                            >
                                {({ handleSubmit, handleChange, errors, touched, values }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Modal.Header closeButton>
                                            <Modal.Title as={"h6"}>Edit SPA Date</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body style={{height: "120px"}}>
                                            {isLdSPA ? (<Loader/>) :(
                                                    <Form.Group>
                                                        <Form.Label>SPA Date</Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            value={values.spaDate}
                                                            isInvalid={errors.spaDate && touched.spaDate}
                                                            onChange={handleChange('spaDate')}
                                                        />
                                                        {errors.spaDate && touched.spaDate && <div className="op-error-message">{errors.spaDate}</div>}
                                                    </Form.Group>
                                                )
                                            }
                                        </Modal.Body>
                                        {!isLdSPA && <Modal.Footer>
                                            <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                        </Modal.Footer>}
                                    </Form>
                                )}
                            </Formik>
                        </Modal>

                        <Modal show={isEditNameID} onHide={setIsEditNameID}>
                            <Formik
                                validationSchema={editCtcDetNameIDSchema}
                                onSubmit={sendEditCtcDetNameID}
                                initialValues={
                                    {   
                                        debtorID: selRowData.debtorID,
                                        salesID: selRowData.salesID,
                                        role: selRowData.role,
                                        fullName: selRowData.debtorName,
                                        idType: selRowData.debtorIDType,
                                        idNum: selRowData.debtorIDNo
                                    }
                                }
                            >
                                {({ handleSubmit, handleChange, errors, touched, values }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Modal.Header closeButton>
                                            <Modal.Title as={"h6"}>Edit Full Name & ID No.</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {isLdNameID ? (<div style={{height: "200px"}}><Loader/></div>) : (
                                                <>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Full Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={ReactHtmlParser(values.fullName)}
                                                            isInvalid={errors.fullName && touched.fullName}
                                                            onChange={handleChange('fullName')}
                                                        />
                                                        {errors.fullName && touched.fullName && <div className="op-error-message">{errors.fullName}</div>}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Identification Type</Form.Label>
                                                        <FormSelect
                                                            options={idTypeContactDet}
                                                            valueDefault={idTypeContactDet.filter(opt => opt.value === values.idType)}
                                                            isInvalid={errors.idType && touched.idType}
                                                            onChange={(e) => {handleChange('idType')(e ? e.value : "")}}
                                                        />
                                                        {errors.idType && touched.idType && <div className="op-error-message">{errors.idType}</div>}
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label>Identification No 1 (Newest)</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={values.idNum}
                                                            isInvalid={errors.idNum && touched.idNum}
                                                            onChange={handleChange('idNum')}
                                                        />
                                                        {errors.idNum && touched.idNum && <div className="op-error-message">{errors.idNum}</div>}
                                                    </Form.Group>
                                                </>
                                            )}
                                        </Modal.Body>
                                        {!isLdNameID && <Modal.Footer>
                                            <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                        </Modal.Footer>}
                                    </Form>
                                )}
                            </Formik>
                        </Modal>

                        <Modal show={isEditCtcDet} onHide={setIsEditCtcDet} size="xl">
                            <Formik
                                validationSchema={editCtcDetSchema}
                                onSubmit={sendEditCtcDet}
                                initialValues={
                                    {
                                        debtorID: selRowData.debtorID,
                                        debtorIDNo: selRowData.debtorIDNo,
                                        salesID: selRowData.salesID,
                                        role: selRowData.role,
                                        fullName: selRowData.debtorName,
                                        phone1Pref: selRowData.phone1Pref,
                                        phone1Num: selRowData.phone1Num,
                                        phone2Pref: selRowData.phone2Pref,
                                        phone2Num: selRowData.phone2Num,
                                        email1: selRowData.email1,
                                        email2: selRowData.email2,
                                        address1: selRowData.address1,
                                        address2: selRowData.address2,
                                        address3: selRowData.address3,
                                        cityID: selRowData.city_id,
                                        postcode: selRowData.postcode,
                                        country: countryLotDet,
                                        contact_level: false
                                    }
                                }
                            >
                                {({ handleSubmit, handleChange, setFieldValue, errors, touched, values }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Modal.Header closeButton>
                                            <Modal.Title as={"h6"}>Edit Contact Details</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="p-0">
                                            {isLdCtcDet ? (<div style={{height: "65vh"}}><Loader/></div>) : (
                                                <Row style={{overflow: "auto", height: "65vh", width: "100%"}} className="py-0 px-4">
                                                    <Col className="py-4">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Full Name</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={values.fullName}
                                                                disabled={true}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Contact Number 1</Form.Label>
                                                            <Row>
                                                                <Col sm={3}>
                                                                    <FormSelect
                                                                        options={contactPrefixLotDetails}
                                                                        valueDefault={contactPrefixLotDetails.filter(opt => opt.value == values.phone1Pref)}
                                                                        isSearchable={true}
                                                                        isInvalid={errors.phone1Pref && touched.phone1Pref}
                                                                        onChange={(e) => {handleChange('phone1Pref')(e ? e.value : "")}}
                                                                    />
                                                                    {errors.phone1Pref && touched.phone1Pref && <div className="op-error-message">{errors.phone1Pref}</div>}
                                                                </Col>
                                                                <Col sm={9}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={values.phone1Num}
                                                                        isInvalid={errors.phone1Num && touched.phone1Num}
                                                                        onChange={handleChange('phone1Num')}
                                                                    />
                                                                    {errors.phone1Num && touched.phone1Num && <div className="op-error-message">{errors.phone1Num}</div>}
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Contact Number 2</Form.Label>
                                                            <Row>
                                                                <Col sm={3}>
                                                                    <FormSelect
                                                                        options={contactPrefixLotDetails}
                                                                        valueDefault={contactPrefixLotDetails.filter(opt => opt.value == values.phone2Pref)}
                                                                        isSearchable={true}
                                                                        onChange={(e) => {handleChange('phone2Pref')(e ? e.value : "")}}
                                                                    />
                                                                </Col>
                                                                <Col sm={9}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        value={values.phone2Num}
                                                                        isInvalid={errors.phone2Num && touched.phone2Pref}
                                                                        onChange={handleChange('phone2Num')}
                                                                    />
                                                                    {errors.phone2Num && touched.phone2Pref && <div className="op-error-message">{errors.phone2Num}</div>}
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Email 1</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={values.email1}
                                                                isInvalid={errors.email1 && touched.email1}
                                                                onChange={handleChange('email1')}
                                                            />
                                                            {errors.email1 && touched.email1 && <div className="op-error-message">{errors.email1}</div>}
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Email 2</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={values.email2}
                                                                isInvalid={errors.email2 && touched.email2}
                                                                onChange={handleChange('email2')}
                                                            />
                                                            {errors.email2 && touched.email2 && <div className="op-error-message">{errors.email2}</div>}
                                                        </Form.Group>
                                                    </Col>

                                                    <Col className="border-start py-4">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Address Line 1</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={values.address1}
                                                                isInvalid={errors.address1 && touched.address1}
                                                                onChange={handleChange('address1')}
                                                            />
                                                            {errors.address1 && touched.address1 && <div className="op-error-message">{errors.address1}</div>}
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Address Line 2</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={values.address2}
                                                                isInvalid={errors.address2 && touched.address2}
                                                                onChange={handleChange('address2')}
                                                            />
                                                            {errors.address2 && touched.address2 && <div className="op-error-message">{errors.address2}</div>}
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Address Line 3</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={values.address3}
                                                                onChange={handleChange('address3')}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Town/City</Form.Label>
                                                            <FormSelect
                                                                options={rrCityList}
                                                                valueDefault={rrCityList.filter(opt => opt.value == values.cityID)}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                isInvalid={errors.cityID && touched.cityID}
                                                                onChange={(e) => {handleChange('cityID')(e ? e.value : "")}}
                                                            />
                                                            {errors.cityID && touched.cityID && <div className="op-error-message">{errors.cityID}</div>}
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Postcode</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={values.postcode}
                                                                isInvalid={errors.postcode && touched.postcode}
                                                                onChange={handleChange('postcode')}
                                                            />
                                                            {errors.postcode && touched.postcode && <div className="op-error-message">{errors.postcode}</div>}
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Country</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={values.country}
                                                                disabled={true}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Modal.Body>
                                        {!isLdCtcDet && <Modal.Footer>
                                            <Form.Check
                                                type="switch"
                                                className="d-flex justify-content-center align-items-center me-3"
                                                label="Contact level"
                                                onChange={(e) => {setFieldValue('contact_level', e.target.checked)}}
                                            />
                                            <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                        </Modal.Footer>}
                                    </Form>
                                )}
                            </Formik>
                        </Modal>

                        <Modal show={isEditDS} onHide={setIsEditDS}>
                            <Formik
                                validationSchema={editDsSchema}
                                onSubmit={sendEditDS}
                                initialValues={
                                    {
                                        debtorID: selRowData.debtorID,
                                        salesID: selRowData.salesID,
                                        dealID: selRowData.outperform_deal_id,
                                        ds_id: selRowData.ds_id
                                    }
                                }
                            >
                                {({ handleSubmit, handleChange, errors, touched, values }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Modal.Header closeButton>
                                            <Modal.Title as={"h6"}>Edit Deal Source</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {isLdEditDS ? <div style={{height: "15vh"}}><Loader /></div> : <Form.Group>
                                                <Form.Label>Deal Source</Form.Label>
                                                <FormSelect
                                                    options={dsList}
                                                    valueDefault={dsList.filter(opt => opt.value == values.ds_id)}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isInvalid={errors.ds_id && touched.ds_id}
                                                    onChange={(e) => {handleChange('ds_id')(e ? e.value : "")}}
                                                />
                                                {errors.ds_id && touched.ds_id && <div className="op-error-message">{errors.ds_id}</div>}
                                            </Form.Group>}
                                        </Modal.Body>
                                        {!isLdEditDS && <Modal.Footer>
                                            <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                        </Modal.Footer>}
                                    </Form>
                                )}
                            </Formik>
                        </Modal>

                        <Modal show={isTransferLot} onHide={setIsTransferLot}>
                            <Formik
                                validationSchema={transferLotSchema}
                                onSubmit={sendTransferLot}
                                initialValues={
                                    {
                                        user_id: '',
                                        team_id: '',
                                        transfer_type: ''
                                    }
                                }
                            >
                                {({ handleSubmit, setFieldValue, errors, touched }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Modal.Header closeButton>
                                            <Modal.Title as={'h6'}>Transfer Unit Ownership</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Stack direction="horizontal" gap={3} className="mb-3">
                                                <Form.Group className="w-100">
                                                    <Form.Label>Select User From *</Form.Label>
                                                    <FormSelect
                                                        options={userList}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        isInvalid={errors.user_id && touched.user_id}
                                                        onChange={(e) => {setFieldValue('user_id', e ? e.value : '')}}
                                                    />
                                                    {errors.user_id && touched.user_id && <div className="op-error-message">{errors.user_id}</div>}
                                                </Form.Group>
                                                <Form.Group className="w-100">
                                                    <Form.Label>Select Team To *</Form.Label>
                                                    <FormSelect
                                                        options={teamList}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                        isInvalid={errors.team_id && touched.team_id}
                                                        onChange={(e) => {setFieldValue('team_id', e ? e.value : '')}}
                                                    />
                                                    {errors.team_id && touched.team_id && <div className="op-error-message">{errors.team_id}</div>}
                                                </Form.Group>
                                            </Stack>
                                            <Form.Group>
                                                <Form.Label>Transfer Type</Form.Label>
                                                <FormSelect
                                                    options={transferTypeList}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    isInvalid={errors.transfer_type && touched.transfer_type}
                                                    onChange={(e) => {setFieldValue('transfer_type', e ? e.value : '')}}
                                                />
                                                {errors.transfer_type && touched.transfer_type && <div className="op-error-message">{errors.transfer_type}</div>}
                                            </Form.Group>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <button type="submit" className="btn op-button op-primary-color text-light">Submit</button>
                                        </Modal.Footer>
                                    </Form>
                                )}
                            </Formik>
                        </Modal>

                        <Modal show={isReserveModal} onHide={setIsReserveModal} size="lg">
                            <Modal.Header closeButton>
                                <Modal.Title as={'h6'}>Unit Reserved</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div style={containerStyleReserve}>
                                    <div className={"ag-theme-quartz ag-op"} style={{ ...gridStyleReserve }}>
                                        <AgGridReact
                                            ref={reserveGridRef}
                                            columnDefs={reserveColumn}
                                            rowData={reserveRowData}
                                            defaultColDef={reportColDef}
                                            rowHeight={50}
                                            rowSelection="single"
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                )
            }
        </>
    );
}

export default LotDetails;