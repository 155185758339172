import { useContext, useState, useEffect, createContext } from "react";

import axios from "../api/axios";
import Loader from "../includes/Loader";
import { useLocation, useParams } from "react-router-dom";
import { useAuth } from "./AuthContext";

const PermissionContext = createContext();

export const PermissionProvider = ({ children }) => {
  const { session } = useAuth();
  const location = useLocation();
  const [permission, setPermission] = useState({});
  const { curation_id, prospect_id } = useParams();

  useEffect(() => {
    const initData = async () => {
      try {
        var module_id = 0;
        switch (location.pathname) {
          case "/report-deal":
            module_id = 6;
            break;
          case "/deal/view/list":
          case "/deal/view/pipeline":
            module_id = 1;
            break;
          case "/activity/list":
          case "/activity/calendar":
            module_id = 2;
            break;
          case "/curation/list":
          case `/curation/${curation_id}`:
            module_id = 3;
            break;
          case `/prospect/${prospect_id}`:
            const gamuda = [180, 200, 190, 202, 203, 212, 213, 251, 210, 402, 412];
            module_id = gamuda.includes(Number(session.company_id)) ? 5 : 25;
            break;
          case `/report-deal`:
            module_id = 6;
            break;
          case `/report-contact`:
            module_id = 7;
            break;
          case `/report-activity`:
            module_id = 8;
            break;
          case `/report-productivity`:
            module_id = 9;
            break;
          case `/report-duplicate-lead`:
            module_id = 10;
            break;
          case `/report-transfer-lead`:
            module_id = 11;
            break;
          case `/report-lead-route`:
            module_id = 12;
            break;
          case `/report-lead-acceptance`:
            module_id = 13;
            break;
          case `/report-deal-performance`:
            module_id = 14;
            break;
          case `/report-deal-engagement`:
            module_id = 15;
            break;
          case `/report-marketing-analytics`:
            module_id = 16;
            break;
          case `/report-inbound-analytics`:
            module_id = 17;
            break;
          case `/report-schedule-deal`:
            module_id = 18;
            break;
          case `/settings/survey-settings`:
            module_id = 27;
            break;
          default:
            module_id = 0;
            break;
        }

        await getPermission(module_id);
      } catch (error) {
        console.error(error);
      }
    };

    initData();
  }, [location.pathname]);

  const getPermission = async (module_id = 0) => {
    try {
      const response = await axios.get("ws/ws_permission.php", {
        params: {
          task: "getUserPermission",
          utoken: session.user_ac_token,
          ctoken: session.company_token,
          permission_module_id: module_id,
        },
      });

      const data = response.data;
      setPermission(data);
    } catch (error) {
      console.error(error);
    }
  };

  const contextData = { permission };
  return <PermissionContext.Provider value={contextData}>{children}</PermissionContext.Provider>;
};

export const usePermission = () => {
  return useContext(PermissionContext);
};

export default PermissionContext;
