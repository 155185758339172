import React, { useState } from "react";
import { Navbar, Nav, Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  faCircleDollar as faCircleDollarActive,
  faAddressBook as faAddressBookActive,
  faUserTie as faUserTieActive,
  faEllipsis as faEllipsisActive,
  faCalendarLines as faCalendarLinesActive,
  faChartPie as faChartPieActive,
  faFileChartPie as faFileChartPieActive,
  faClipboardCheck as faClipboardCheckActive,
  faHouseCircleCheck as faHouseCircleCheckActive,
  faMessages as faMessagesActive,
  faPhone as faPhoneActive,
  faLightbulbExclamationOn as faLightbulbExclamationOnActive,
  faChartLineUp as faChartLineUpActive,
  faUser as faUserActive,
  faListCheck as faListCheckActive,
  faBicycle as faBicycleActive,
  faDiagramProject as faDiagramProjectActive,
  faLoader as faLoaderActive,
  faClone as faCloneActive,
  faRectangleAd as faRectangleAdActive,
  faCalendarWeek as faCalendarWeekActive,
  faFilterCircleDollar as faFilterCircleDollarActive,
  faUsersRectangle as faUsersRectangleActive,
  faBuilding as faBuildingActive,
  faChartPyramid as faChartPyramidActive,
  faTicket as faTicketActive,
  faSquarePollVertical as faSquarePollVerticalActive,
  faRectangleList as faRectangleListActive,
  faBullhorn as faBullhornActive,
  faCalendarCircleUser as faCalendarCircleUserActive,
  faSquarePollHorizontal as faSquarePollHorizontalActive,
  faMessageSms as faMessageSmsActive,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faMessageSms,
  faRectangleList,
  faSquarePollVertical,
  faCircleDollar,
  faAddressBook,
  faEllipsisStroke,
  faCalendarLines,
  faChartPie,
  faFileChartPie,
  faUserTie,
  faClipboardCheck,
  faHouseCircleCheck,
  faMessages,
  faPhone,
  faLightbulbExclamationOn,
  faChartLineUp,
  faUser,
  faListCheck,
  faBicycle,
  faDiagramProject,
  faLoader,
  faClone,
  faRectangleAd,
  faCalendarWeek,
  faFilterCircleDollar,
  faUsersRectangle,
  faBuilding,
  faChartPyramid,
  faTicket,
  faBullhorn,
  faCalendarCircleUser,
  faSquarePollHorizontal,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OutperformLogo from "../assets/images/outperform_logo_menu.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import { NavLink } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import GlPlayIcon from "../assets/images/glplay.svg";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Sidebar = () => {
  const { session } = useAuth();
  const [showModules, setShowModules] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showStats, setShowStats] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const gamuda = [251, 180, 190, 202, 203, 212, 213, 341, 106, 2, 400, 409, 200, 210];
  const closeCanvas = () => {
    setShowModules(false);
    setShowReport(false);
    setShowStats(false);
    setShowContact(false);
  };

  const leadAgingAccess = () => {
    // const company = [251, 180, 190, 202, 203, 212, 213, 341, 106, 2, 400, 409, 210, 200, 385];
    if (Number(session.lead_aging) === 1) {
      return true;
    } else {
      return false;
    }
  };

  const organizationAccess = () => {
    if (Number(session.company_type_id) !== 2) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-column flex-shrink-0 sidebar-menu">
        <Navbar expand="md" className="menu-layout">
          <Nav className="menu-side">
            <Nav.Item className="menu-logo">
              <Nav.Link href="#" className="menu-logo-link">
                <img src={OutperformLogo} className="menu-logo-img" alt="logo_menu" />
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className="menu-item">
              <OverlayTrigger trigger={["hover", "hover"]} placement="right" overlay={<Tooltip className="op-font">Deals</Tooltip>}>
                <NavLink to="/deal/list" className="nav-link menu-link" activeclassname="active" onClick={closeCanvas}>
                  {({ isActive }) => <FontAwesomeIcon icon={isActive ? faCircleDollarActive : faCircleDollar} size="xl" />}
                </NavLink>
              </OverlayTrigger>
            </Nav.Item>

            <Nav.Item className="menu-item">
              <OverlayTrigger trigger={["hover", "hover"]} placement="right" overlay={<Tooltip className="op-font">Contacts</Tooltip>}>
                <Nav.Link
                  className={`nav-link menu-link ${showContact ? "active" : ""}`}
                  onClick={() => {
                    setShowContact(true);
                    setShowReport(false);
                    setShowModules(false);
                    setShowStats(false);
                  }}
                >
                  <FontAwesomeIcon icon={showContact ? faAddressBookActive : faAddressBook} size="xl" />
                </Nav.Link>
              </OverlayTrigger>
            </Nav.Item>

            <Nav.Item className="menu-item">
              <OverlayTrigger trigger={["hover", "hover"]} placement="right" overlay={<Tooltip className="op-font">Activity</Tooltip>}>
                <NavLink to="/activity" className="nav-link menu-link" activeclassname="active" onClick={closeCanvas}>
                  {({ isActive }) => <FontAwesomeIcon icon={isActive ? faCalendarLinesActive : faCalendarLines} size="xl" />}
                </NavLink>
              </OverlayTrigger>
            </Nav.Item>

            {Number(session.curation_enabled) !== 0 && (
              <Nav.Item className="menu-item">
                <OverlayTrigger trigger={["hover", "hover"]} placement="right" overlay={<Tooltip className="op-font">Curation</Tooltip>}>
                  <NavLink to="/curation" className="nav-link menu-link" activeclassname="active" onClick={closeCanvas}>
                    {({ isActive }) => <FontAwesomeIcon icon={isActive ? faClipboardCheckActive : faClipboardCheck} size="xl" />}
                  </NavLink>
                </OverlayTrigger>
              </Nav.Item>
            )}

            <Nav.Item className="menu-item">
              <OverlayTrigger trigger={["hover", "hover"]} placement="right" overlay={<Tooltip className="op-font">Statistics</Tooltip>}>
                <Nav.Link
                  className={`nav-link menu-link ${showStats ? "active" : ""}`}
                  onClick={() => {
                    setShowReport(false);
                    setShowModules(false);
                    setShowContact(false);
                    setShowStats(true);
                  }}
                >
                  <FontAwesomeIcon icon={showStats ? faChartPieActive : faChartPie} size="xl" />
                </Nav.Link>
              </OverlayTrigger>
            </Nav.Item>

            {Number(session.role_id) === 1 && (
              <Nav.Item className="menu-item">
                <OverlayTrigger trigger={["hover", "hover"]} placement="right" overlay={<Tooltip className="op-font">Report</Tooltip>}>
                  <Nav.Link
                    className={`nav-link menu-link ${showReport ? "active" : ""}`}
                    onClick={() => {
                      setShowReport(true);
                      setShowModules(false);
                      setShowContact(false);
                      setShowStats(false);
                    }}
                  >
                    <FontAwesomeIcon icon={showReport ? faFileChartPieActive : faFileChartPie} size="xl" />
                  </Nav.Link>
                </OverlayTrigger>
              </Nav.Item>
            )}

            {Number(session.role_id) === 1 && (
              <Nav.Item className="menu-item">
                <OverlayTrigger trigger={["hover", "hover"]} placement="right" overlay={<Tooltip className="op-font">Users</Tooltip>}>
                  <NavLink to="/users" className="nav-link menu-link" activeclassname="active" onClick={closeCanvas}>
                    {({ isActive }) => <FontAwesomeIcon icon={isActive ? faUserTieActive : faUserTie} size="xl" />}
                  </NavLink>
                </OverlayTrigger>
              </Nav.Item>
            )}

            {Number(session.booking_module) === 1 && (
              <Nav.Item className="menu-item">
                <OverlayTrigger trigger={["hover", "hover"]} placement="right" overlay={<Tooltip className="op-font">Booking Dashboard</Tooltip>}>
                  <NavLink to="/booking" className="nav-link menu-link" activeclassname="active" onClick={closeCanvas}>
                    {({ isActive }) => <FontAwesomeIcon icon={isActive ? faHouseCircleCheckActive : faHouseCircleCheck} size="xl" />}
                  </NavLink>
                </OverlayTrigger>
              </Nav.Item>
            )}

            <Nav.Item className="menu-foot">
              <OverlayTrigger trigger={["hover", "hover"]} placement="right" overlay={<Tooltip className="op-font">Modules</Tooltip>}>
                <Nav.Link
                  className={`menu-modules ${showModules ? "active" : ""}`}
                  onClick={() => {
                    setShowModules(true);
                    setShowReport(false);
                    setShowStats(false);
                  }}
                >
                  <FontAwesomeIcon icon={showModules ? faEllipsisActive : faEllipsisStroke} size="xl" />
                </Nav.Link>
              </OverlayTrigger>
            </Nav.Item>
          </Nav>
        </Navbar>
      </div>

      <Offcanvas show={showModules} onHide={setShowModules} style={{ left: 56, width: 300 }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Modules</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav variant="pills" className="flex-column report-tab">
            {Number(session.whatsapp_conversation) !== 0 && (
              <Nav.Item className="mb-2">
                <NavLink to="/conversation" className="nav-link" activeclassname="active" onClick={() => setShowModules(false)}>
                  {({ isActive }) => (
                    <div className="d-flex">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faMessagesActive : faMessages} size="xl" className="me-2" />
                      </div>
                      Conversation
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}

            {Number(session.avanser_enabled) === 1 && (
              <Nav.Item className="mb-2">
                <NavLink to="/call-analytics" className="nav-link" activeclassname="active" onClick={() => setShowModules(false)}>
                  {({ isActive }) => (
                    <div className="d-flex">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faPhoneActive : faPhone} size="xl" className="me-2" />
                      </div>
                      Call Analytics
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}

            {Number(session.avanser_enabled) === 1 && (
              <Nav.Item className="mb-2">
                <NavLink to="/call-insight" className="nav-link" activeclassname="active" onClick={() => setShowModules(false)}>
                  {({ isActive }) => (
                    <div className="d-flex">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faLightbulbExclamationOnActive : faLightbulbExclamationOn} size="xl" className="me-2" />
                      </div>
                      Call Insight
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}

            {Number(session.whatsapp_campaign) === 1 && (
              <Nav.Item className="mb-2">
                <NavLink to="/whatsapp-campaign" className="nav-link" activeclassname="active" onClick={() => setShowModules(false)}>
                  {({ isActive }) => (
                    <div className="d-flex">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faWhatsapp : faWhatsapp} size="xl" className="me-2" />
                      </div>
                      Whatsapp Campaign
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}

            <Nav.Item className="mb-2">
              <NavLink to="/campaign-dashboard" className="nav-link" activeclassname="active" onClick={() => setShowModules(false)}>
                {({ isActive }) => (
                  <div className="d-flex">
                    <div style={{ width: 30 }}>
                      <FontAwesomeIcon icon={isActive ? faBullhornActive : faBullhorn} size="xl" className="me-2" />
                    </div>
                    Campaign Dashboard
                  </div>
                )}
              </NavLink>
            </Nav.Item>

            {Number(session.event_rsvp) === 1 && (
              <Nav.Item className="mb-2">
                <NavLink to="/event-rsvp" className="nav-link" activeclassname="active" onClick={() => setShowModules(false)}>
                  {({ isActive }) => (
                    <div className="d-flex">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faCalendarCircleUserActive : faCalendarCircleUser} size="xl" className="me-2" />
                      </div>
                      Event RSVP
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}

            {Number(session.survey) === 1 && Number(session.role_id) === 1 && (
              <Nav.Item className="mb-2">
                <NavLink to="/survey-dashboard" className="nav-link" activeclassname="active" onClick={() => setShowModules(false)}>
                  {({ isActive }) => (
                    <div className="d-flex">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faSquarePollHorizontalActive : faSquarePollHorizontal} size="xl" className="me-2" />
                      </div>
                      Survey Dashboard
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}

            {Number(session.company_id) === 251 && (
              <Nav.Item className="mb-2">
                <NavLink to="/gamuda/redemption/dashboard" className="nav-link" activeclassname="active" onClick={() => setShowModules(false)}>
                  {({ isActive }) => (
                    <div className="d-flex">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faTicketActive : faTicket} size="xl" className="me-2" />
                      </div>
                      Redemption
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={showReport} onHide={setShowReport} style={{ left: 56, width: 300 }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Report</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav variant="pills" className="flex-column report-tab">
            {Number(session.company_id) === 251 && (
              <Nav.Item className="mb-2">
                <NavLink to="/gamuda/report-incoming-log" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                  {({ isActive }) => (
                    <div className="d-flex align-items-center">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faRectangleListActive : faRectangleList} size="xl" className="me-2" />
                      </div>
                      <span style={{ lineHeight: 0 }}>Incoming Log Report</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}

            {Number(session.company_id) === 251 && (
              <Nav.Item className="mb-2">
                <NavLink to="/gamuda/report-outgoing-log" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                  {({ isActive }) => (
                    <div className="d-flex align-items-center">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faRectangleListActive : faRectangleList} size="xl" className="me-2" />
                      </div>
                      <span style={{ lineHeight: 0 }}>Outgoing Log Report</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}

            {Number(session.company_id) === 251 && (
              <Nav.Item className="mb-2">
                <NavLink to="/gamuda/report-gl-play" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                  {({ isActive }) => (
                    <div className="d-flex align-items-center">
                      <div style={{ width: 30 }}>
                        <img src={GlPlayIcon} alt="gl" />
                      </div>
                      <span style={{ lineHeight: 0 }}>GL Play Report</span>
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}

            <Nav.Item className="mb-2">
              <NavLink to="/report-open-deal" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                {({ isActive }) => (
                  <div className="d-flex">
                    <div style={{ width: 30 }}>
                      <FontAwesomeIcon icon={isActive ? faCircleDollarActive : faCircleDollar} size="xl" className="me-2" />
                    </div>
                    Status Summary
                  </div>
                )}
              </NavLink>
            </Nav.Item>

            {Number(session.layout_mode) === "real-estate" && (Number(session.company_lead_assignment_autoreroute) === 1 || Number(session.company_lead_assignment_autoreroute) === 0) && (
              <Nav.Item className="mb-2">
                <NavLink to="/report-inbound-lead-performance" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                  {({ isActive }) => (
                    <div className="d-flex">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faChartLineUpActive : faChartLineUp} size="xl" className="me-2" />
                      </div>
                      Inbound Lead Performance
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}

            <Nav.Item className="mb-2">
              <NavLink to="/report-contact" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                {({ isActive }) => (
                  <div className="d-flex">
                    <div style={{ width: 30 }}>
                      <FontAwesomeIcon icon={isActive ? faUserActive : faUser} size="xl" className="me-2" />
                    </div>
                    Contact Report
                  </div>
                )}
              </NavLink>
            </Nav.Item>

            <Nav.Item className="mb-2">
              <NavLink to="/report-deal" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                {({ isActive }) => (
                  <div className="d-flex">
                    <div style={{ width: 30 }}>
                      <FontAwesomeIcon icon={isActive ? faCircleDollarActive : faCircleDollar} size="xl" className="me-2" />
                    </div>
                    Deal Report
                  </div>
                )}
              </NavLink>
            </Nav.Item>

            <Nav.Item className="mb-2">
              <NavLink to="/report-activity" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                {({ isActive }) => (
                  <div className="d-flex">
                    <div style={{ width: 30 }}>
                      <FontAwesomeIcon icon={isActive ? faListCheckActive : faListCheck} size="xl" className="me-2" />
                    </div>
                    Activity Report
                  </div>
                )}
              </NavLink>
            </Nav.Item>

            <Nav.Item className="mb-2">
              <NavLink to="/report-productivity" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                {({ isActive }) => (
                  <div className="d-flex">
                    <div style={{ width: 30 }}>
                      <FontAwesomeIcon icon={isActive ? faBicycleActive : faBicycle} size="xl" className="me-2" />
                    </div>
                    Productivity Report
                  </div>
                )}
              </NavLink>
            </Nav.Item>

            <Nav.Item className="mb-2">
              <NavLink to="/report-lead-route" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                {({ isActive }) => (
                  <div className="d-flex">
                    <div style={{ width: 30 }}>
                      <FontAwesomeIcon icon={isActive ? faDiagramProjectActive : faDiagramProject} size="xl" className="me-2" />
                    </div>
                    Lead Routing
                  </div>
                )}
              </NavLink>
            </Nav.Item>

            <Nav.Item className="mb-2">
              <NavLink to="/report-lead-acceptance" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                {({ isActive }) => (
                  <div className="d-flex">
                    <div style={{ width: 30 }}>
                      <FontAwesomeIcon icon={isActive ? faDiagramProjectActive : faDiagramProject} size="xl" className="me-2" />
                    </div>
                    Lead Acceptance
                  </div>
                )}
              </NavLink>
            </Nav.Item>

            <Nav.Item className="mb-2">
              <NavLink to="/report-deal-performance" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                {({ isActive }) => (
                  <div className="d-flex">
                    <div style={{ width: 30 }}>
                      <FontAwesomeIcon icon={isActive ? faChartLineUpActive : faChartLineUp} size="xl" className="me-2" />
                    </div>
                    Deal Performance
                  </div>
                )}
              </NavLink>
            </Nav.Item>

            <Nav.Item className="mb-2">
              <NavLink to="/report-deal-engagement" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                {({ isActive }) => (
                  <div className="d-flex">
                    <div style={{ width: 30 }}>
                      <FontAwesomeIcon icon={isActive ? faLoaderActive : faLoader} size="xl" className="me-2" />
                    </div>
                    Deal Engagement
                  </div>
                )}
              </NavLink>
            </Nav.Item>

            <Nav.Item className="mb-2">
              <NavLink to="/report-duplicate-lead" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                {({ isActive }) => (
                  <div className="d-flex">
                    <div style={{ width: 30 }}>
                      <FontAwesomeIcon icon={isActive ? faCloneActive : faClone} size="xl" className="me-2" />
                    </div>
                    Duplicate Lead Report
                  </div>
                )}
              </NavLink>
            </Nav.Item>

            <Nav.Item className="mb-2">
              <NavLink to="/report-marketing-analytics" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                {({ isActive }) => (
                  <div className="d-flex">
                    <div style={{ width: 30 }}>
                      <FontAwesomeIcon icon={isActive ? faRectangleAdActive : faRectangleAd} size="xl" className="me-2" />
                    </div>
                    Marketing Analytics
                  </div>
                )}
              </NavLink>
            </Nav.Item>

            {Number(session.inbound_analytics) === 1 && (
              <Nav.Item className="mb-2">
                <NavLink to="/report-inbound-analytics" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                  {({ isActive }) => (
                    <div className="d-flex">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faRectangleAdActive : faRectangleAd} size="xl" className="me-2" />
                      </div>
                      Inbound Analytics
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}

            <Nav.Item className="mb-2">
              <NavLink to="/report-schedule-deal" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                {({ isActive }) => (
                  <div className="d-flex">
                    <div style={{ width: 30 }}>
                      <FontAwesomeIcon icon={isActive ? faCalendarWeekActive : faCalendarWeek} size="xl" className="me-2" />
                    </div>
                    Scheduled Deals
                  </div>
                )}
              </NavLink>
            </Nav.Item>

            {Number(session.lead_transfer_report) !== 0 && (
              <Nav.Item>
                <NavLink to="/report-transfer-lead" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                  {({ isActive }) => (
                    <div className="d-flex">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faDiagramProjectActive : faDiagramProject} size="xl" className="me-2" />
                      </div>
                      Lead Transfer Report
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}

            <Nav.Item className="mb-2">
              <NavLink to="/report-sms-campaign" className="nav-link" activeclassname="active" onClick={() => setShowReport(false)}>
                {({ isActive }) => (
                  <div className="d-flex">
                    <div style={{ width: 30 }}>
                      <FontAwesomeIcon icon={isActive ? faMessageSmsActive : faMessageSms} size="xl" className="me-2" />
                    </div>
                    SMS Campaign
                  </div>
                )}
              </NavLink>
            </Nav.Item>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={showStats} onHide={setShowStats} style={{ left: 56, width: 300 }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Statistics</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav variant="pills" className="flex-column report-tab">
            <Nav.Item className="mb-2">
              <NavLink to="/statistics" className="nav-link" activeclassname="active" onClick={() => setShowStats(false)}>
                {({ isActive }) => (
                  <div className="d-flex">
                    <div style={{ width: 30 }}>
                      <FontAwesomeIcon icon={isActive ? faChartPieActive : faChartPie} size="xl" className="me-2" />
                    </div>
                    Statistics
                  </div>
                )}
              </NavLink>
            </Nav.Item>

            {leadAgingAccess() && (
              <Nav.Item className="mb-2">
                <NavLink to="/lead-aging" className="nav-link" activeclassname="active" onClick={() => setShowStats(false)}>
                  {({ isActive }) => (
                    <div className="d-flex">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faFilterCircleDollarActive : faFilterCircleDollar} size="xl" className="me-2" />
                      </div>
                      Lead Aging Report
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}

            {Number(session.blueprint_ai) === 1 && (
              <Nav.Item className="mb-2">
                <NavLink to="/ai-outreach" className="nav-link" activeclassname="active" onClick={() => setShowStats(false)}>
                  {({ isActive }) => (
                    <div className="d-flex">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faUsersRectangleActive : faUsersRectangle} size="xl" className="me-2" />
                      </div>
                      AI Outreach Dashboard
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}

            {gamuda.includes(Number(session.company_id)) && (
              <Nav.Item className="mb-2">
                <NavLink to="/cs1" className="nav-link" activeclassname="active" onClick={() => setShowStats(false)}>
                  {({ isActive }) => (
                    <div className="d-flex">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faSquarePollVerticalActive : faSquarePollVertical} size="xl" className="me-2" />
                      </div>
                      Customer Survey 1
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}

            {gamuda.includes(Number(session.company_id)) && (
              <Nav.Item className="mb-2">
                <NavLink to="/cs2" className="nav-link" activeclassname="active" onClick={() => setShowStats(false)}>
                  {({ isActive }) => (
                    <div className="d-flex">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faSquarePollVerticalActive : faSquarePollVertical} size="xl" className="me-2" />
                      </div>
                      Customer Survey 2
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={showContact} onHide={setShowContact} style={{ left: 56, width: 300 }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Contacts</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav variant="pills" className="flex-column report-tab">
            <Nav.Item className="mb-2">
              <NavLink to="/prospect/list" className="nav-link" activeclassname="active" onClick={() => setShowContact(false)}>
                {({ isActive }) => (
                  <div className="d-flex">
                    <div style={{ width: 30 }}>
                      <FontAwesomeIcon icon={isActive ? faAddressBookActive : faAddressBook} size="xl" className="me-2" />
                    </div>
                    People
                  </div>
                )}
              </NavLink>
            </Nav.Item>

            {[251, 180, 200, 190, 203, 202, 212, 213, 210].includes(Number(session.company_id)) && (
              <Nav.Item className="mb-2">
                <NavLink to="/gamuda/list-tier" className="nav-link" activeclassname="active" onClick={() => setShowContact(false)}>
                  {({ isActive }) => (
                    <div className="d-flex">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faChartPyramidActive : faChartPyramid} size="xl" className="me-2" />
                      </div>
                      Gamuda Tier
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}

            {organizationAccess() && (
              <Nav.Item className="mb-2">
                <NavLink to="/organizations/list" className="nav-link" activeclassname="active" onClick={() => setShowContact(false)}>
                  {({ isActive }) => (
                    <div className="d-flex">
                      <div style={{ width: 30 }}>
                        <FontAwesomeIcon icon={isActive ? faBuildingActive : faBuilding} size="xl" className="me-2" />
                      </div>
                      Organization
                    </div>
                  )}
                </NavLink>
              </Nav.Item>
            )}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
};

export default Sidebar;
